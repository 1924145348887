 import { extend, override } from 'flarum/extend';
import CommentPost from 'flarum/components/CommentPost';
import DiscussionPage from 'flarum/components/DiscussionPage';
import DiscussionControls from 'flarum/utils/DiscussionControls';
import LogInModal from 'flarum/components/LogInModal';
import TextEditor from 'flarum/components/TextEditor';
import HeaderPrimary from 'flarum/common/components/HeaderPrimary';

import EditorButton from "./components/EditorButton";

app.initializers.add('zhuxishun/reply2see', () => {

    let isEditor = 0;
    let iconStyle='fas fa-reply';
    let index = 1;

    extend(TextEditor.prototype, 'oninit', function () {
      this.textareaId = 'textarea' + (index++);
    });

    extend(TextEditor.prototype, 'toolbarItems', function (items) {
        isEditor  = app.forum.attribute('zhuxishun-reply2see.editor');
        iconStyle = app.forum.attribute('zhuxishun-reply2see.editor_button_icon');
        if(isEditor ==  1) {
            items.add('reply',
                ( <EditorButton title={app.translator.trans('zhuxishun-reply2see.forum.editor_button_title')}
                    dataid={this.textareaId}  icon={iconStyle}
                    style={{ prefix: '[reply]', suffix: '[/reply]', trimFirst: true }}/>
                ),-10
            );
        }
      });

extend(CommentPost.prototype, 'config', function()
{
  if (app.session.user && app.current instanceof DiscussionPage)
    $('.reply2see_reply').off('click').on('click',
        () => DiscussionControls.replyAction.call(app.current.discussion, true, false)
    );
  else
    $('.reply2see_reply').off('click').on('click',
        () => app.modal.show(new LogInModal())
    );
});
});
