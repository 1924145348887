import Component from 'flarum/common/Component';
import apply from '../../../../../reply/js/src/forum/util/apply';
import icon from 'flarum/helpers/icon';

export default class EditorButton extends Component {
  oncreate(vnode) {
    super.oncreate(vnode);

    this.$().tooltip();
  }

  view() {
    return (
      <button className="Button Button--icon Button--link"  title={this.attrs.title} onclick={this.click.bind(this)}> {icon(this.attrs.icon)}</button>
    );
  }


  click() {
    return apply(this.element, this.attrs);
  }

}
